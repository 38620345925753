import React from "react"

import Layout from "@src/components/Layout"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"

import Sdk from "@src/icons/screenshots/sdk-shadow.png"
import FrontendWithScalableBackend from "@src/icons/screenshots/frontend-with-scalable-backend.png"
import OG from "@src/icons/og/OG_Frontend_Frameworks.jpeg"
import ConnectIcon from "@src/icons/v2/connect.svg"

import Splash from "./components/Splash"
import Row from "../components/Row"
import ImageRow from "./components/ImageRow"

const IndexPage = ({ location }) => {
  return (
    <Layout
      title="Consume API that integrates with all the frontend frameworks "
      description="Pick a frontend framework of your choice. Our auto generated REST and GraphQL  APIs can be consumed easily."
      url="https://canonic.dev/features/frontend-framework"
      location={location}
      ogImage={OG}
    >
      <Splash
        title="Consume API that integrates with all the frontend frameworks "
        subtitle="Pick a frontend framework of your choice. Our auto generated REST and GraphQL  APIs can be consumed easily."
        icon={<ConnectIcon />}
      />
      <Row>
        <ImageRow
          title="Save development time, build Frontend with SDK"
          subtitle="Our SDK provides you with custom components to rapidly build your frontend without writing repeatable blocks of code."
          screenshot={Sdk}
        />
      </Row>
      <Row>
        <ImageRow
          title="Build frontend with a scalable backend "
          subtitle="Our APIs are highly scalable, making sure they don't break under increased load."
          screenshot={FrontendWithScalableBackend}
          reverse
        />
      </Row>
      <Callout location={location} title="frontendFrameworks" />
      <CommunityCallout />
    </Layout>
  )
}

export default IndexPage
